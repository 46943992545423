html {
  --primary: #de222a;
}

/* body {
  overflow: hidden;
} */

/* for less table data */
.min-table-height {
  min-height: 130px;
}

/* for clock font size */
.font-10px {
  font-size: 10px;
}
.font-size-14 {
  font-size: 14px;
}

/* for toast notification */
.toast-notification {
  text-align: center;
}

/* active page in pagination */
.pagination-active {
  background-color: #de222a;
  background-color: var(--primary);
  color: #fff;
}
/* no pagination style */
.no-pagination-style {
  height: 33px;
}

/* table data loading */
.loading {
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #de222a;
  border: 2px solid var(--primary);
  border-top: 2px solid transparent;
  animation: loading-animation 1s infinite linear;
  -webkit-animation: laoding-animation 1s infinite linear;
  -moz-animation: laoding-animation 1s infinite linear;
  z-index: 1000;
}
@-webkit-keyframes laoding-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes laoding-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* background of language */
.fk-language__flag {
  background-size: cover;
}

/* hide pagination */
@media screen and (min-width: 1200px) {
  .table-bottom-info-hide {
    height: calc(100vh - 120px);
  }
}

/* image in table td */
.table-img-large {
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/assets/img/waiter.jpg");
}

/* manage page border-right show */
.simplebar-content {
  margin-right: -16px !important;
}

/* translation-input */
.translation-input {
  max-width: 150px;
}

/*  custom-bg-secondary */
.custom-bg-secondary {
  background-color: #ebeef6;
}

/* variation-min-price-input-width */
.variation-min-price-input-width {
  min-width: 250px;
}

/* text-decoration: line-through; */
.text-deleted {
  text-decoration: line-through;
}

/*Logo background on load*/
.fk-brand__img--fk {
  background-image: none;
}

/*  text-green for workperiod */
.text-green {
  color: #28a745;
}

/* Calculator css override */
.calculator {
  width: 350px;
}

/* payment type multiple clear all button hide */
.payment-type-parent .select__indicator.select__clear-indicator {
  display: none !important;
}
.payment-type-parent .select__multi-value__remove {
  display: none !important;
}
.payment-type-parent .select__multi-value {
  min-width: 60px !important;
  text-align: center !important;
}
.payment-type-parent .select__multi-value__label {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

/* Pos page group name shaddow remove */
.btn-outline-danger:focus {
  box-shadow: none !important;
}

/* reverse the ordre list items */
.reverse-this {
  display: flex;
  flex-direction: column-reverse;
}

/* for remove button from order list in POS */
.make-this-relative {
  position: relative;
  top: 26px;
  left: 0;
  margin-top: -31px;
  margin-left: 48%;
  cursor: pointer;
  max-width: 51.88px;
}

.sicky-bottom {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
}

.make-this-relative-mobile {
  position: relative;
  top: -53px;
  left: 25px;
  /* margin-top: -31px; */
  margin-left: 25%;
  cursor: pointer;
  max-width: 51.88px;
}

/*  pointer-cursor */
.pointer-cursor {
  cursor: pointer;
}

/*  change-background */
.change-background {
  background-color: #f64e60 !important;
}

/* new-customer-pos */
.new-customer-pos {
  font-weight: 450 !important;
  font-size: 12px !important;
}

/* pos page work period css */
.fk-left-over {
  position: relative;
}
.fk-left-overlay {
  border-radius: 4px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  background: #f64e5fa9;
  z-index: 3;
}
@media screen and (min-width: 768px) {
  .fk-left-overlay {
    position: absolute;
  }
}
.fk-left-overlay__content {
  min-width: 400px;
  background-color: #ffffffef;
  padding: 20px;
  border-radius: 4px;
}

/* checkbox align item center cancel */
.mx-checkbox {
  align-items: flex-start !important;
}
.mx-checkbox__input-sm {
  margin-top: 12px !important;
}
.mt-0-kitchen {
  margin-top: 0px !important;
}

/* POS Page Tab Screen Order List */
li.addons-list__item.active {
  background: #de222a;
  color: white !important;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* profile update */
.profile-update-full {
  height: calc(100vh - 174px) !important;
}

.no-order {
  height: calc(100vh - 230px);
}

.main-height-100 {
  height: calc(100vh - 20px) !important;
}

.installation-full-page {
  height: calc(100vh - 30px) !important;
}

.login-page-background {
  background-image: url("/assets/img/logo.png") !important;
}

/* safari pos issue */
.fk-dish-card__img {
  align-items: center;
}

.congratulation-page {
  height: calc(100vh - 30px) !important;
  display: flex;
  width: 100%;
}

.congratulation-page-margin {
  margin: auto;
}

/* Updates of settle */

.myBorder {
  border-top: 1px dotted black;
  border-bottom: 1px dotted black;
}

.myBorderTop {
  margin-top: 10px;
  border-top: 1px dotted black;
}
.myBorderBottom {
  border-bottom: 1px dotted black;
}

.myBorderTopCustomer {
  border-top: 1px dotted black;
}

/* SETTLE STYLE */
.fk-settle-container {
  height: calc(100vh - 100px);
}
.fk-settle {
  background: #fff;
  height: calc(100vh - 130px);
}
.fk-settle__products {
  height: calc(100vh - 198px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.fk-settle__products .simplebar-content {
  overflow-x: hidden;
}
.fk-settle-group__label {
  display: inline-block;
}
.fk-settle-group__input {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.fk-settle-cal {
  height: calc(100vh - 241px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.fk-settle-cal-btn {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 18px;
  transition: all 0.3s ease;
}
@media screen and (min-width: 768px) {
  .fk-settle-cal-btn {
    font-size: 30px;
  }
}
@media screen and (min-width: 992px) {
  .fk-settle-cal-btn {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .fk-settle-cal-btn {
    font-size: 30px;
  }
}

.t-bg-w {
  background: #fff;
}
.t-bg-p {
  background: #3ad38d;
}
.t-bg-p:hover {
  background: #2bc580;
}

.t-bg-d {
  background: #23282c;
}
.t-bg-ac {
  /* background: #0073AA; */
  background: #23282c77;
}
.t-bg-r {
  background: #fe574e;
}
.fk-settle__pay {
  height: calc(100vh - 160px);
}

/* Pos page dept tag */

.button {
  display: inline-block;
  font-family: "Montserrat", "Trebuchet MS", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  padding: 0.8em 1.4em;
  padding-right: 4.7em;
  background: #009ed8;
  border: none;
  color: white;
  transition: 0.2s;
}
.button:before,
.button:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-top: inherit;
  padding-bottom: inherit;
  width: 2.8em;
  content: "\00a0";
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  text-align: center;
  transition: 0.2s;
  -webkit-transform-origin: 50% 60%;
          transform-origin: 50% 60%;
}
.button:before {
  background: rgba(0, 0, 0, 0.1);
}
.button:hover {
  background: #0079a5;
}
.button:active,
.button:focus {
  background: #002e3f;
  outline: none;
}
.button {
  min-width: 100%;
}
.arrow {
  background: #09c2de;
}
.arrow:hover {
  background: #09c2de;
}
.arrow:active,
.arrow:focus {
  background: #0ac090;
}
.button.arrow.active {
  background: #0ac090;
}
.arrow:after {
  content: "\F054";
}
.arrow:hover:after {
  -webkit-animation: bounceright 0.3s alternate ease infinite;
  animation: bounceright 0.3s alternate ease infinite;
}
/* active crust */
.activeCrust {
  background: #0ac090;
}

.activeCrust:hover {
  background: #0ac090;
}

.addonPadding48 {
  padding-top: 35px;
}

.addonPadding35 {
  padding-top: 26px;
}

.addonPadding24 {
  padding-top: 0px;
}

.akash-table-for-print-padding.table > :not(caption) > * > * {
  padding: 0.2rem 0.5rem;
}

/* website */
.custom-nav .nav-link {
  display: block;
  padding: 0.5rem 0.9rem !important;
}

.langSelect {
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #2a435d;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
/* website variation drawer */
.kh-drawer__body_cart {
  height: calc(100vh - 200px);
  background: #ffffff;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
  position: relative;
  height: 300px;
}

/* custom button */
.btn-gradient-mini {
  padding: 4px 12px;
  font-size: 12px;
  margin: 5px;
  border: none !important;
}

/* Saas profile card */
.saas_card_profile {
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 15px 39.2px 0.8px rgba(17, 0, 92, 0.1);
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
  margin-right: 20px;
}
.upper_bg_shape {
  height: 150px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #ffd9cf;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.saas_user_img {
  border-radius: 50%;
  outline: 1px solid #f33d3d;
  position: relative;
  z-index: 22;
}
.avatar_img_wrapper {
  margin-left: 35px;
  margin-bottom: 30px;
}

.saas_card_profile .user_title {
  background: #eff3ff;
  border-left: 2px solid #f33d3d;
padding: 20px;
  width: 95%;
  margin: 0 auto;
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 35px;
}
.saas_card_profile .user_title .icon {
  font-weight: 400;
  margin-right: 15px;
}

.user_info_wrapper {
padding-left: 40px;
margin-right: 40px;
}
.user_info_wrapper .user_icon{
  width: 50px;
  height: 50px;
  border-radius: 16px;
  text-align: center;
  line-height: 50px;
  color: #002e3f;
  display: inline-block;
  border: 1px solid #002e3f;
  margin-right: 15px;
}

.user_info_wrapper .list_wrapper {
  font-size: 18px;
  display: flex;
  font-weight: 700;
  margin-bottom: 30px;
}

.user_info_wrapper .title_wrapper .title {
  font-size: 18px;
  display: block;
  font-weight: 700;
}
.user_info_wrapper .title_wrapper .subtitle {
  font-size: 14px;
  display: block;
  font-weight: 400;
}

.wrapper-margin-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

/* subs title  */
.saas_subs_title {
  font-weight: 500;
  text-transform: capitalize;
}
.saas_subs_title .saas_subs_icon{
  background-color: #f33d3d;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius:50%;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  }

.saas_subs_title .color-red{
  color: #f33d3d;
  font-weight: 700;
}
/* single_saas_info_card*/
.single_saas_info_card {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.1);
    padding: 10px;
}
.single_saas_info_card .saas_info_img{
    max-width: 100px;
    max-height: 70px;
    margin-bottom: 10px;
    display: flex;
   margin-right: auto;
   margin-left: auto;

}
.single_saas_info_card .count_number{
    font-size: 36px;
    font-weight: 900;
    display: block;
    text-align: center;
}
.single_saas_info_card .title{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    text-align: center;
}

/* saas_profile_history_wrapper */
.saas_history_form {
  border: 1px solid #ffeee9;
}
.saas_profile_history_wrapper {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.1);
    padding: 30px;
    height: 633px;
    overflow-y: scroll;
}

.saas_payment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saas_payment_header .title {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}
.report-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  display: inline-block;
}
/* saas_history_table */
.saas_history_head  {
background: #ffeee9;
}

.saas_history_table_row {
  border-bottom: 1px solid #ffeee9;
}
.saas_history_table_body {
  border: 1px solid #ffeee9;
}

/* loading  style*/
.saas_card_loading {
  width: 30px;
  margin: 0 auto;
}

.history_rolling {
  margin-top: 100px;
  margin-left: 255%;
}
.payment-btn {
  display: inline-block;
  padding: 10px!important;
  border-radius: 5px!important;
  width: initial;
  font-size: 12px!important;
  font-weight: 500;
  border: none;
  /* margin-top: 12px; */
}

.demo-wrapper {
  /* background-image: url(..); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.t-container {
  width: 1800px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}


.demo-link {
  width: 100%;
  height: 370px;
  box-shadow: 0 5px 15px rgba(60, 76, 198, 0.2);
  overflow-y: scroll;
  transition: all 7s ease !important;
}
.demo-link img {
  width: 100%;
}
.demo__coming {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 25px;
  color: #374a5e;
  text-transform: uppercase;
  font-weight: 500;
}

/* Template One */
.blog-area .single-blog.template-single-product .blog-img .b-badge {
  position: absolute;
  top: 7px;
  right: -4px;
  background: #cc3333;
  color: #ffffff;
  padding: 3px 17px;
}

.blog-area .single-blog.template-single-product {
  margin-right: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  padding: 60px 10px;
  box-shadow: -6px 2px 29px 13px rgb(0 21 100 / 8%);
}
.customWidth-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

/* template-one-modal */
.template-one-modal {
  position: relative;
  flex: 1 1 auto;
  border: none;
  outline: none;
  border-radius: 63px;
  padding: 2px!important;
  background:#dc3545;
  border-color:#dc3545;
}

/* slick slider */
.slider-gallery-img.template-gallery .slick-slider .slick-track {
display: flex!important;
align-items: center!important;
}

.slider-gallery-img.template-gallery .slick-slide div img {
  height: 280px;
  max-width: 255px!important;
}

/* template settigs */
.template-show-card {
  width: 240px;
  height: 400px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.template-show-card .preview-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 1s;
}
.template-show-card:hover .preview-img{
top: -60%;
}
.templateradio {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 11;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border: 2px solid #e2ebf6;
  border-radius: 50%;
  cursor: pointer;
  height: 1.5em;
  outline: none;
  transition: 
    background 0.2s ease-out,
    border-color 0.2s ease-out;
  width: 1.5em; 
  
}
.templateradio::after {
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: '';
  display: block;
  height: 0.75rem;
  left: 25%;
  position: absolute;
  top: 50%;
  -webkit-transform: 
    rotate(45deg)
    translate(-50%, -50%);
          transform: 
    rotate(45deg)
    translate(-50%, -50%);
  width: 0.375rem;
}
.templateradio:checked {
  background: #f64e60;
  border-color: #f64e60;
}
.template-active-btn {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 50px;
  height: 50px; 
   font-size: 1px;
  overflow: hidden;
   border-radius: 50%; 
  transition: .5s;
}
.template-active-btn:hover {
  width: 135px;
  height: 40px;
  font-size: 1rem;
  border-radius: 0;
}

.banner-area .banner-content .buyone-shape .banner-badge::after {
display: none;
}
.banner-area .banner-content .buyone-shape .banner-badge {
  font-size: 18px!important;
}

.add-to-cart {
  padding: 2px 8px;
  text-transform: capitalize;
  background: #f64e60;
  border-radius: 5px;
  color: #fff;
  border: none;
  display: block;
}
.small-text {
  font-size:  14px;
  
}

/* reservation-table styles */
.reservation-table .react-bootstrap-table .table {
  table-layout: auto;
}

.add_booking_form .react-datepicker-wrapper {
 display: block;
}
.add_booking_form .react-time-picker {
  /* display: block; */
  width: 100%;

}
.add_booking_form .react-time-picker__wrapper {
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* reservation and table management */
.reservation_wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100vh;
}
.rsv_side_menubar {
  list-style: none;
  background: #FFDCDC;
  width: 100px;
  height: 115%;
  padding: 100px 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
}
.rsv_side_menubar li {
  background: transparent;
  width: 60px;
  height: 60px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  transition: .3s;
}
.rsv_side_menubar li.active {
background:#dc3545;
}
.rsv_side_menubar li.active a {
color: white;
}
.rsv_side_menubar li:hover {
background:#dc3545;
}
.rsv_side_menubar li.hover-none:hover {
background: initial;
}
.rsv_side_menubar li:hover a {
color: white;
}
.rsv_side_menubar li .title{
  font-size: 10px;
  font-weight: 700;
}
.rsv_side_menubar li a {
  text-decoration: none;
}

/* right side rsv content */
.rsv_right_content {
  width: calc(100% - 100px);
}
.rsv_right_content .top-header {
  background: #012A70;
  padding: 10px 20px;
  color: white;
}
.rsv_right_content .top-header .date-wrapper .icon{
  background: #de222a;
  background: var(--primary);
  padding: 5px 8px;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
  display: inline-block;
}

/* rsv_bottom content */
.rsv_btm_content {
  display: flex;
  position: relative;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.rsv_left_btm_sidebar {
  /* width: 340px; */
  width: 420px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 15px;
  background: #fff;
  box-shadow: 3px 0px 10px rgba(0, 0, 0,.16);
}
.rsv_date_info {
  box-shadow: 0px 5px 15px rgba(0, 0, 0,.10);
  border-radius: 7px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rsv_date_info .icon{
  font-size: 40px;
}
.rsv_date_info .separator{
  font-size: 40px;
  font-weight: 400;
}
.rsv_date_info .title{
  font-size: 20px;
  font-weight: 700;
  display: block;
}
.rsv_date_info .subtitle{
  font-size: 14px;
  font-weight: 400;
  display: block;
}

/* rsv_party_list */
.rsv_party_list {
  border: 1px solid #E8E8E8;
  padding: 15px;
  border-radius: 7px;
  display: flex;
  position: relative;
}

.rsv_list_action_button {
  position: absolute;
  top: 10px;
  right: 10px;
}


.rsv_party_list {
  flex-wrap: wrap;
  cursor: pointer;
}
/* will change it */
.rsv_party_list:hover .party_rsv_option {
  /* opacity: 1;
  visibility: visible; */
}
.rsv_party_list .show-status{
  /* width: 70px;
  height: 20px;
  background: #F6F6F6; */
  display: block;
  border-radius: 7px;
  margin-top: 5px;
}
.rsv_party_list .total-size{
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #FFD9D9;
  color: #de222a;
  color: var(--primary);
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}


/* rsv_right_table_plan_wrapper */
.rsv_right_table_plan_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% - 340px);
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 70px 100px;
  background: #fff;
  box-shadow: 3px 0px 10px rgb(0 0 0 / 16%);
}
.single-table-layout{
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;

}
.single-table-layout.large-table.booked{
  border: 4px solid #0ac090;
}
/* large table */
.single-table-layout.large-table {
  background: #ffe1a0;
  width: 80px;
  height: 125px;
  padding-top: 22px;
  line-height: 2;
}

.single-table-layout .title{
  position: relative;
  font-size: 10px;
  font-weight: 700;
  display: block;
  z-index: 1;
}

.single-table-layout .time{
  position: relative;
  font-size: 12px;
  font-weight: 500;
  display: block;
  z-index: 1;
}

/* traingle table */
.single-table-layout.traingle-table {
  width: 90px;
  height: 90px;
  padding: 10px 18px;
  position: relative;
  z-index: 1;
  background: transparent;
}
.single-table-layout.traingle-table::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #D9E8FF;
  width: 90px;
  height: 90px;
  z-index: -1;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 5px;
}
.single-table-layout.booked.traingle-table::after {
  border: 4px solid #0ac090;
}

/* circle table */
.single-table-layout.circle-table {
  width: 100px;
  height: 100px;
  padding-top: 9px;
  border-radius: 50%;
  background: #F0AFFF;
}
.single-table-layout .title {
  line-height: 3;
}

.single-table-layout.circle-table.booked {
  border:4px solid #0ac090;
}
/* party_rsv_option */
.party_rsv_option {
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  width: 320px;
  border-radius: 10px;
  position: absolute;
  z-index: 11;
  background: #fff;
  top: 0;
  /* left: 107%; */
  left: 0;
  box-shadow: 10px 20px 60px rgba(32, 80, 114,.10);
  width: 100%;
}

.position-rsv-option-close {
  position: absolute;
  top: 10px;
  right: 0;
}

.party_rsv_option.active {
  opacity: 1;
  visibility: visible;
}

.party_rsv_option {
padding: 20px 0;
}
.party_rsv_option .title-box {
  background: #F6F6F6;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.party_rsv_option .single-option{
  width: 80px;
  height: 60px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #de222a;
  border: 1px solid var(--primary);
  background: white;
 color: #de222a;
 color: var(--primary);
  padding-top: 7px;
  font-weight: 600;
  text-transform: capitalize;
}
.party_rsv_option .single-option.active{
  background: #de222a;
  background: var(--primary);
  border: 1px solid #de222a;
  border: 1px solid var(--primary);
  color: white;
}

/* assign-box-wrapper */
.assign-box-wrapper  {
  position: relative;
}

.assign-box-wrapper::before  {
content: initial;
position: absolute;
top: -172px;
left: -23px;
width: 0;
height: 0;
border-style: solid;
border-width: 10px 25px 10px 0;
border-color: transparent #fff transparent transparent;
line-height: 0px;
_border-color: #000000 #fff #000000 #000000;
_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');

}
.assign-box-wrapper .day{
  display: inline-block;
  background: #F6F6F6;
  padding: 0px 10px;
  text-transform: capitalize;
}

.assign-box-wrapper .info-wrapper {
  font-size: 14px;
  color: #CBCBCB;
  border-bottom: 2px dashed #CBCBCB;
}
 .btn-green {
  background: #03C23D;
  color: white;
  text-transform: capitalize;
  border-radius: 5px;
  box-shadow: 3px 0px 10px rgb(0 0 0 / 16%);
}

.hover-btn-bg:hover {
  background: #F6F6F6;
}

/* add party */
.rsv_grp_btn{
  color: #fff;
  background-color: #1F7CF6!important;
  border-color: #1F7CF6!important;
}
.rsv_grp_btn:hover{
  color: #fff;
}
.rsv_grp_btn_outline{
  background: #fff;
  color: #1F7CF6!important;
  border-color: #1F7CF6!important;
  transition: .5s;
}
.rsv_grp_btn_outline:hover{
  color: #fff!important;
  background-color: #1F7CF6!important;
  border-color: #1F7CF6!important;
}
.history-assign-box-wrapper::before {
  content: initial;
}

.rsv_right_table_plan_wrapper .react-datepicker-wrapper {
  width: 100%!important;
}

.stopwatch-style {
  font-size: 10px;
  position: relative;
  bottom: 8px;
  background: #04BB10!important;
  padding: 2px 5px;
}
.bg-lite-green {
  background: #D9FFDC!important;
}
.bg-green {
  background: #04BB10!important;
  color: #fff!important;
}
.position-abs-left-btm-0 {
position: absolute;
left: 0;
bottom: 28px;
}
.position-abs-left-top-0 {
position: absolute;
left: 0;
top: 0;
}

.section-header-table-area span{
  font-size: 14px;
  font-weight: 500;
}
.section-header-table-area .option{
color: #04BB10;
}

.table-book-option {
  visibility: visible;
  opacity: 1;
  position: relative;
}

.single-table-option .icon{
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
  background: #fff;
  border: 1px solid #04BB10;
  color: #04BB10;
  cursor: pointer;
}
.single-table-option.active .icon{
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
  background: #04BB10;
  color: #fff;
}
.single-table-option:hover .icon{
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
  background: #04BB10;
  color: #fff;
}
.single-table-option .title{
font-weight: 300;
text-transform:  lowercase;
margin-top: 5px;
}
.overflow-y {
  overflow-y: scroll!important;
}
.height-100vh {
  height: 100vh!important;
}
.cusrsor-pointer {
  cursor: pointer;
}
/* .basic-multi-select {
  z-index: 99;
} */
.banner-gallery h3,p {
  position: relative;
  z-index: 1;
}
.rsv_server_list {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}
.rsv_server_list.show {
  height: auto;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

/* responsive */
@media (max-width: 1199px) {
  .product-card__title {
    font-size: 24px;
}
.template-show-card {
  width: 200px;
}

}
@media (max-width: 991px) {
  /* rsv table */
  .rsv_side_menubar {
    width: 70px;
    padding: 170px 10px;
}
.rsv_right_content {
  width: calc(100% - 70px);
}
/* .rsv_left_btm_sidebar {
  width: 200px;
} */
.rsv_date_info {
  flex-wrap: wrap;
}
.rsv_date_info .separator {
display: none;
}

.rsv_party_list .show-status {
  margin-bottom: 9px;
}

.rsv_right_table_plan_wrapper {
  width: calc(100% - 200px);
}
  /* rsv table end */
  .product-card__title {
    font-size: 24px;
}
.xsm-text {
  font-size: 11px;
}
.simplebar-content {
  margin-right: initial !important;
}
}
@media (max-width: 768px) {
  .react-datepicker-wrapper {
    display: block!important;
}

/* rsv list start */
.rsv_right_table_plan_wrapper {
  padding: 70px 20px;
}
/* history */
.history-table {
  height: 100vh;
}
.history-table::-webkit-scrollbar {
  width: 2px;
}
.history-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  }

  .rsv_left_btm_sidebar {
    position: absolute;
    left: -100%;
    top: 0px;
    width: 0;
    z-index: 3;
    background: white;
    transition: .3s;
  }
  .rsv_left_btm_sidebar.show {
    left: 0;
    width: 100%;
  }
  .rsv_right_table_plan_wrapper {
    position: absolute;
  }
/* rsv list end */
}
/* website btn toggle */
.website-btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.website-btn-toggle:focus,
.website-btn-toggle:focus.active,
.website-btn-toggle.focus,
.website-btn-toggle.focus.active {
  outline: none;
}
.website-btn-toggle:before,
.website-btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.website-btn-toggle:before {
  content: "Off";
  left: -4rem;
}
.website-btn-toggle:after {
  content: "On";
  right: -4rem;
  opacity: 0.5;
}
.website-btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.website-btn-toggle.active {
  transition: background-color 0.25s;
}
.website-btn-toggle.active {
  background-color: #29b5a8;
}
.website-btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.website-btn-toggle.active:before {
  opacity: 0.5;
}
.website-btn-toggle.active:after {
  opacity: 1;
}
.transparent {
  opacity: 0;
  visibility: hidden;
}
.height-80vh {
  height: 80vh;
}
.table-reset-container {
  position: absolute;
  top: 20px;
  right: 20px;
}
.fz-12 {
  font-size: 12px;
}

.vh-70 {
  height: 70vh;
}
.fz-12 {
  font-size: 12px;
}
.custom-hr {
  background: black;
  opacity: 0.7;
  height: 4px;
  /* width: 80%; */
  margin: 10px auto;
  padding: 2px;
}
.w-33 {
  width: 33.33%;
}
.mr-20 {
  margin-right: 20px;
}
/* .draggable_parent-wrapper-box {
  position: relative;
  width: 700px;
    height: 500px;
    background-color: black;
}
.draggable_el-inner-box {
  position: absolute;
  width: 100%;
  height: 100px;
  border: solid 1px green;
  transition: all 5s;
}
.draggable_el-inner-box .react-draggable{
  position: absolute;
} */

/* .simplebar-scrollbar {
  width: 30px;
}
.horizontal.simplebar-track .simplebar-scrollbar {
  height: 30px;
  top: -7px;
} */
.Display {
  width: 100%;
  height: 30%;
  background-color: #323232;
  font-size: 60px;
  color: white;
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
}

.DisplaySpan {
  margin: 16px;
}

.Button {
  color: white;
  width: 25%;
  height: 14%;
  font-size: 6vmin;
  border-color: black;
}

.Button:focus {
  outline: 0px;
}

.Button:active {
  opacity: 0.5;
}

.BlackButton {
  background-color: #434343;
}

.GrayButton {
  background-color: #5d5e5e;
}

.OrangeButton {
  background-color: #de222a;
}

.LargeButton {
  width: 50%;
  text-align: left;
  padding-left: 12%;
}

.Calculator {
  height: calc(100vh - 180px);
  display: block;
}

